import React from 'react'

import './PageSponsorSection.scss'

const PageSponsorSection = props => (
  <section className="page-sponsor-section" id="page-sponsor-section">
    <div className="container">
      <h1>{props.heading} </h1>
      <p>{props.paragraph}</p>
      <p>{props.paragraph2}</p>
      <p>{props.paragraph3}</p>
      <ul>
        <li>{props.li1}</li>
        <li>{props.li2}</li>
        <li>{props.li3}</li>
        <li>{props.li4}</li>
      </ul>

      <p>
        Please email{' '}
        <strong>
          <a href="mailto:{props.email}">{props.paragraph4}</a>
        </strong>{' '}
        for any questions about sponsorship or partnership opportunities.
      </p>
    </div>
  </section>
)

export default PageSponsorSection
