import React from 'react'

import Layout from '../utils/Layout/Layout'
// import Header from '../components/Header/Header'
import PageHeader from '../components/PageHeader/PageHeader'
// import VideoSection from '../components/VideoSection/VideoSection';
import PageSponsorSection from '../components/PageSponsorSection/PageSponsorSection'
import ParticipateSection from '../components/ParticipateSection/ParticipateSection'
import EmailSuscribe from '../components/EmailSuscribe/EmailSuscribe'
// import PageList from '../components/PageList/PageList'
import SEO from '../components/seo'

const SponsorPage = () => (
  <Layout>
    <SEO title="Sponsor AfroFest MN" />
    <PageHeader heading="Sponsor AfroFest MN" paragraph="" />
    <PageSponsorSection
      heading="Become a sponsor"
      paragraph="AfroFest will be an annual outdoor community event that celebrates Africa’s diverse cultures through live musical performances, drum & dance, arts, museum quality artifacts, colorful hand woven fabrics & textiles, storytelling, and food."
      paragraph2="Your partnership with the 2019 AfroFest will help fortify our celebration by supporting opportunities for festival participants to showcase their talent and innovation, and build network and community connections."
      paragraph3="Join us as a trailblazer for this unique event for the Twin Cities. As an AfroFest sponsor, you have the opportunity to:"
      li1="Partner at the ground level with an exciting event; positioned for growth over the next three years"
      li2="Display your organizations’ commitment to grassroots community building efforts"
      li3="Enjoy broad exposure to your brand at the event, through publicity around the event and event advertising and marketing efforts"
      li4="Network with local and international community members, vendors, businesses, services, and community groups"
      paragraph4="mohamed@afrodeli.com"
    />
    <EmailSuscribe />
    <ParticipateSection />
  </Layout>
)

export default SponsorPage
